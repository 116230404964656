import {BaseModel} from "./base-model";
import {FundSummary} from "./fund";
import {FormField, FormFields} from "../shared/form-helper";
import {Bank} from "./bank";

class Address extends BaseModel {
  country_id: string;
  country_name: string;
  unit_no: string;
  postal: string;
  line1: string;
  line2: string;

  fullAddress() {
    let ad = '';
    if (this.line1 != null) {
      ad += this.line1;
    }
    if (this.line2 != null) {
      ad += ' ' + this.line2;
    }
    if (this.country_name != null) {
      ad += ' ' + this.country_name;
    }
    if (this.postal != null) {
      ad += ' ' + this.postal;
    }
    return ad;
  }
}

class PersonalInfo extends BaseModel {
  title: string;
  full_name: string;
  first_name: string;
  last_name: string;
  mobile_cc: string;
  mobile_no: string;
}

class Company extends BaseModel {
  name: string;
  website: string;
  about_us: string;
  registration_no: string;
  registration_issued_date: string;
  registration_expiry_date: string;
  office_no: string;
  industry_id: string;
}

class BusinessInfo extends BaseModel {
  business_type: number;
  company: Company;
  // director: Director;
  registeredAddress: Address;

  init(value) {

    if (value.company) {
      this.company = new Company(value.company);
    }
    if (value.registeredAddress) {
      this.registeredAddress = new Address(value.registeredAddress);
    }
  }

}

export const UserStatic = {

  KYC_TYPE_BORROWER_BUSINESS: 1,
  KYC_TYPE_BORROWER_PERSONAL: 2,
  KYC_TYPE_INVESTOR_PERSONAL: 3,
  KYC_TYPE_INVESTOR_BUSINESS: 4, // not used yet

  ACCOUNT_INVESTOR: 1,
  ACCOUNT_SUPPLIER: 2,
  ACCOUNT_BUYER: 3,

  KYC_STATUS_DISABLED: 0,
  KYC_STATUS_INCOMPLETE: 1,
  KYC_STATUS_PENDING_APPROVAL: 2,
  KYC_STATUS_APPROVED: 3,
  KYC_STATUS_REJECTED_RESUBMIT: 4,
  KYC_STATUS_PENDING_ARTEMIS: 5,

  USER_TYPE_MAKER : 1,
  USER_TYPE_CHECKER : 2,
  USER_TYPE_APPROVER : 3,


  PRODUCT_TYPE_LOAN : 1,
  PRODUCT_TYPE_AR : 2,
  PRODUCT_TYPE_MC : 3,
  PRODUCT_TYPE_AROB : 4,
};

export class CompanyFormFields extends FormFields {
  name = new FormField({
    label: 'Company Name',
    placeHolder: 'Company Name',
    required: true,
  });
  registration_no = new FormField({
    label: 'Registration No',
    placeHolder: 'SEC/TIN',
    required: true,
  });
  website = new FormField({
    label: 'Website',
    placeHolder: 'Website',
  });
  about_us = new FormField({
    label: 'About Us',
    placeHolder: 'About Us',
  });
  industry_id = new FormField({
    label: 'Industry',
    placeHolder: 'Industry',
    required: true,
  });
}

export class PersonalInfoFormFields extends FormFields {
  title = new FormField({
    label: 'Title',
    placeHolder: 'Title',
  });
  full_name = new FormField({
    label: 'Director\'s  Full Name',
    placeHolder: 'Name as per registration ID/Passport',
    required: true,
  });
  first_name = new FormField({
    label: 'First Name',
    placeHolder: 'First Name',
  });
  last_name = new FormField({
    label: 'Last Name',
    placeHolder: 'Last Name',
  });
}

export class AddressFormFields extends FormFields {
  country_id = new FormField({
    label: 'Country',
    placeHolder: 'Country',
    // controlConfig: [Validators.compose([
    //   Validators.required
    // ])]
    required: true,

  });
  postal = new FormField({
    label: 'Postal',
    placeHolder: 'Postal Code',
    // controlConfig: ['', Validators.compose([
    //   Validators.required
    // ])]
    required: true,
  });
  unit_no = new FormField({
    label: 'Unit No',
    placeHolder: 'Unit No',
    // controlConfig: ['', Validators.compose([
    //   Validators.required
    // ])]
  });
  line1 = new FormField({
    label: 'Line 1',
    placeHolder: 'Address Line 1',
    required: true,
  });
  line2 = new FormField({
    label: 'Line 2',
    placeHolder: 'Address Line 2',
  });
}

export class BusinessInfoFormFields extends FormFields {
  company = new CompanyFormFields();
  registeredAddress = new AddressFormFields();
}

export class BankFormFields extends FormFields {

  account_name = new FormField({
    label: 'Bank Account Holder Name'
  });

  account_number = new FormField({
    label: 'Bank Account Number'
  });

  bank_name = new FormField({
    label: 'Bank Name',
    value: 'ROBINSONS BANK',
    disabled: true
  });

  branch_code = new FormField({
    label: 'Bank Code'
  });

}


export class AccountFormFields extends FormFields {
    businessInfo = new BusinessInfoFormFields();
    personalInfo = new PersonalInfoFormFields();
    buyer_company_name = new FormField({
        label: 'Buyer',
        placeHolder: '',
    });
    signup_code = new FormField({
        label: '',
        placeHolder: 'Verification Code',
    });
    photo_id = new FormField({
        label: 'Logo'
    });

}


export class Buyer extends BaseModel {
  id: number;
  company: {name: string};
  stats: {
    total_invoices: number
    pending_checks: number
    pending_approval: number
  };

  _loader = false;

}


export class User extends BaseModel {
  id: number;

  username: string;
  email: string;
  status: number;
  status_label: string;
  parent_id: number;
  user_type: number;

  photo_url: string;

  // company_name: string;
  // company_logo: string;

  // Customer data
  // full_name: string;
  // first_name: string;
  // last_name: string;
  // gender: string;
  // gender_label: string;
  role_label: string;

  hash: string;
  hashRaw: string;

  last_login_at: number;
  last_login_ip: string;

  companyBank: Bank;
  fundSummary: FundSummary;
  businessInfo: BusinessInfo;
  personalInfo: PersonalInfo;
  lastKycRequest: { reason: string };
  loanSummary: any;
  loanSummaryDefaultBuyer: any;
  is_matrix: number;
  fundingAccounts:any[];
  matrix: {
    maker: number,
    checker: number,
    approver: number,
  };
  buyers: Buyer[];

  is_auto_availment_allowed: boolean;
  is_auto_availment: boolean;

  current_kyc: number
  current_kyc_status: number
  current_kyc_status_label: number

  reset_password_token: string;
  default_buyer_id: string;
  default_buyer: {id:string, name : string, is_payment_term_extendable : boolean, is_under_maintenance : boolean, payment_terms : string[], default_payment_terms : number};

  init(value) {
    if (value.fundSummary) {
      this.fundSummary = new FundSummary(value.fundSummary);
    }
    if (value.businessInfo) {
      this.businessInfo = new BusinessInfo(value.businessInfo);
    }
    if (value.personalInfo) {
      this.personalInfo = new PersonalInfo(value.personalInfo);
    }
    if (value.companyBank) {
      this.companyBank = new Bank(value.companyBank);
    }
    if (value.buyers) {
      this.buyers = value.buyers.map(v => (new Bank(v))) ;
    }
    if (value.buyers_stats) {
      this.buyers = value.buyers_stats.map(v => (new Bank(v))) ;
    }
    super.init(value);
  }


  static genderLabel(val) {

    if (val == 'F') {
      return 'Female';
    } else if (val == 'M') {
      return 'Male';
    }

  }

  isParent() {
    return this.parent_id == null;
  }

  isMaker() {
    return this.user_type == UserStatic.USER_TYPE_MAKER;
  }

  isChecker() {
    return this.user_type == UserStatic.USER_TYPE_CHECKER;
  }

  isApprover() {
    return this.user_type == UserStatic.USER_TYPE_APPROVER;
  }

  canReject(){
    return (this.isParent() || this.isChecker() || this.isApprover());
  }

  hasApprover(){
    return (this.matrix && this.matrix.approver > 0);
  }

  canCreateRequest(){
    return (this.isMaker() || this.isParent());
  }

  canCancel(){
    if (!this.isParent() ) {
      let allowed = false;
      if (this.isChecker() && !this.hasApprover()) {
         allowed = true;
      }else if (this.isApprover() ) {
         allowed = true;
      }
      return allowed;
    }
    return true;
  }

  canApprove(){
    return ( this.isParent() || this.isChecker() || this.isApprover());
  }

  canEdit() {
    return this.isParent() && (this.current_kyc_status == UserStatic.KYC_STATUS_INCOMPLETE || this.current_kyc_status == UserStatic.KYC_STATUS_REJECTED_RESUBMIT);
  }

  mobileNo() {
    if (this.personalInfo) {
      return this.personalInfo.mobile_cc + '' + this.personalInfo.mobile_no;
    }
    return '';
  }

  isKycApproved() {
    return this.current_kyc_status == UserStatic.KYC_STATUS_APPROVED;
  }

  isKycPending() {
    return this.current_kyc_status == UserStatic.KYC_STATUS_PENDING_APPROVAL;
  }

  isKycResubmit() {
    return this.current_kyc_status == UserStatic.KYC_STATUS_REJECTED_RESUBMIT;
  }


  getKycStatusClass() {
    return {
      'text-success': this.current_kyc_status === UserStatic.KYC_STATUS_APPROVED,
      'text-warning': (this.current_kyc_status === UserStatic.KYC_STATUS_PENDING_APPROVAL || this.current_kyc_status === UserStatic.KYC_STATUS_PENDING_ARTEMIS),
      'text-danger': (this.current_kyc_status === UserStatic.KYC_STATUS_DISABLED || this.current_kyc_status === UserStatic.KYC_STATUS_REJECTED_RESUBMIT || this.current_kyc_status === UserStatic.KYC_STATUS_INCOMPLETE),
    };
  }
}

