import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {environment} from "../environments/environment";
import {JwtModule} from "@auth0/angular-jwt";
import {UserService} from "./model/user.service";
import {GlobalService} from "./model/global.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SharedModule} from "./shared/shared.module";
import {SimpleLayoutComponent} from "./containers/simple-layout/simple-layout.component";
import {SidenavLayoutComponent} from './containers/sidenav-layout/sidenav-layout.component';
import {AuthGuard} from "./model/auth.guard";
import {setAppInjector} from "./model/app-injector";
import {SettingDataService} from "./model/setting-data.service";
import {UserDataService} from "./model/user.data.service";
import {SharedService} from "./shared/shared.service";
import {InvDocumentService} from "./modules/invoice/models/inv-document.service";
import {CalendarModule} from "primeng";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const APP_CONTAINERS = [
  SimpleLayoutComponent,
  SidenavLayoutComponent
];

const tokenGetter = () => {
  return localStorage.getItem(environment.tokenName) || '';
};

declare global {
  interface Window {
    Web3: any;
    web3: any;
  }
}
window.Web3 = window.Web3 || {};
window.web3 = window.web3 || {};

@NgModule({
  declarations: [
    AppComponent,

    ...APP_CONTAINERS,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,

    AppRoutingModule,

    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.apiHost]
      }
    }),

    CalendarModule,

    NgbModule


  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard,
    UserService,
    GlobalService,
    SettingDataService,
    UserDataService,
    InvDocumentService,
    SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
