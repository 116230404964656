import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

import {environment} from '../../environments/environment';
import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {User} from './user';

@Injectable()
export class UserService {
  redirectURL = '';
  loggedIn = false;
  termsAndConditions = new BehaviorSubject<boolean>(false);

  private userDataSource = new BehaviorSubject({});
  public observeUserData: Observable<User> = this.userDataSource.asObservable() as Observable<User>;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
  ) {

    this.loggedIn = this.isLoggedIn();

  }

  public setTermsAndConditionsValue(value){
    this.termsAndConditions.next(value);
  }

  public getTermsAndConditionsValue(){
    return this.termsAndConditions.asObservable();
  }

  setAccessToken(access_token) {

    localStorage.setItem(
      environment.tokenName,
      access_token
    );
    this.loggedIn = true;

  }

  public login(username, password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/login'),
        JSON.stringify({
          username: username,
          password: password,
        }),
        {
          headers: headers
        }
      ).pipe(
        map(response => {
          if (response.success) {

            this.setAccessToken(response.data['access_token']);
            // this.fetchServerData();

          } else {
            localStorage.removeItem(environment.tokenName);
            this.loggedIn = false;
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public changePassword(id,password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .put<ResponseBody>(
        GlobalService.getUrl('/user/'+id+'/change-password'),
        JSON.stringify({
          password: password
        }),
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }


  public signup(data) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/signup'),
        data,
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {

            if (typeof response.data['access_token'] !== 'undefined') {

              localStorage.setItem(
                environment.tokenName,
                response.data['access_token']
              );
              this.loggedIn = true;

            }

          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public validateSignup(data) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/validate-signup'),
        data,
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {

            if (typeof response.data['access_token'] !== 'undefined') {

              localStorage.setItem(
                environment.tokenName,
                response.data['access_token']
              );
              this.loggedIn = true;

            }

          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public signupConfirm(id, auth_key) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/confirm'),
        {
          id: id, auth_key: auth_key
        },
        {headers: headers}
      ).pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetRequest(email) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-request'),
        {
          email: email,
          role: 'user'
        },
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetOtpTokenVerification(email,otp_token, otp_code) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-otp-token-verification'),
        {email,otp_token, otp_code},
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetTokenVerification(token) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-token-verification'),
        {token: token},
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordReset(token, password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset'),
        {
          token: token,
          password: password
        },
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public logout(): void {
    localStorage.removeItem(environment.tokenName);
    this.loggedIn = false;
  }

  public getToken(): any {
    return localStorage.getItem(environment.tokenName) || '';
  }

  public getId(): any {
    const jwtVal = this.getJWTValue();
    if (jwtVal && jwtVal.data && jwtVal.data.id) {
      return jwtVal.data.id;
    }
    return null;

  }

  public unauthorizedAccess(error: any): void {
    this.logout();
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    try{
        return this.jwtHelper.isTokenExpired(this.getToken()) !== true;
    }catch(e){
        return;
    }
  }


  public getJWTValue(): any {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      return this.jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  public getData(fromServer?: boolean): Observable<User> {

    if (fromServer) {
      this.fetchServerData();
    } else {
      const _userData = localStorage.getItem('user');
      if (_userData) {
        const user = JSON.parse(_userData);
        this.userDataSource.next(user);
      }
    }
    return this.observeUserData.pipe(map(response => {
      return new User(response);
    }));

  }

  public setLocalData(userData: any) {
    this.userDataSource.next(userData);
    localStorage.setItem(
      'user',
      JSON.stringify(userData)
    );
  }

  public fetchServerData() {
    if (this.loggedIn) {
      this.getMe({
        expand: 'fundSummary,default_buyer'
      }).subscribe(user => {
        this.setLocalData(user);
      });
    }
  }

  setStoreVariable(key, val) {
    localStorage.setItem(key, val);
  }

  getStoreVariable(key): string {
    return localStorage.getItem(key);
  }

  public getMe(query = {}): Observable<User> {

    const headers = GlobalService.getHeaders();
    return this.http
      .get<ResponseBody>(
        GlobalService.getUrl('/user/me', query),
        {headers: headers}
      ).pipe(
        map(response => {
          const user = new User(response.data);
          this.setLocalData(user);
          return (user);
        }),
        catchError(GlobalService.handleError)
      );

  }

  updateUser(userData, headers = null): Observable<User> {

    if (headers == null) {
      headers = GlobalService.getHeaders();
    }

    return this.http
      .put<ResponseBody>(
        GlobalService.getUrl('/user/me'),
        userData,
        {headers: headers}
      ).pipe(
        map(response => {
          return new User(response.data);
        }),
        catchError(GlobalService.handleError));
  }


  updateUserKyc(userData): Observable<User> {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/me/kyc', {expand: 'businessInfo,personalInfo'}),
        userData,
        {headers: headers}
      ).pipe(
        map(response => {
          return (new User(response.data));
        }),
        catchError(GlobalService.handleError));
  }


  verifyOnboardingToken(token): Observable<any> {
    // const user = new User({
    //   personalInfo: {
    //     full_name: 'John Doe',
    //   },
    //   email: 'ryan1@pilon.sg',
    //   businessInfo: {
    //     company: {name: 'John Doe\' F&B'},
    //     registeredAddress: {
    //       country_name: 'Singapore',
    //     }
    //   }
    // });
    //
    // return of({user: user});

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-token-verify'),
        {token: token}
      ).pipe(
        map(response => {
          this.setAccessToken(response.data.access_token);
          return (new User(response.data.user));
        }),
        catchError(GlobalService.handleError)
      );
  }

  sendEmailOtp(): Observable<any> {

    const headers = GlobalService.getHeaders();
    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-generate-otp'),
        {},
        {headers: headers}
      ).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );

  }

  confirmOnboardingOtp(otp_token, otp_code): Observable<any> {

    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-verify-otp'),
        {otp_token, otp_code},
        {headers: headers}
      ).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );

  }


  updateUserDummy(userData): Observable<User> {
    const user = new User();
    return of(user);
  }

  updateAutoAvailmentPreference(auto_availment_status): Observable<any> {

    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/auto-availment'),
        {auto_availment_status},
        {headers: headers}
      ).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );
  }

}
