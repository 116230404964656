import Swal, {SweetAlertIcon} from 'sweetalert2';
// import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {Injectable} from '@angular/core';

// import {BsModalRef, BsModalService} from 'ngx-bootstrap';

export function modalClose() {
  Swal.close();
}

export function modalError(text, callback = null) {

  Swal.fire({
    icon: 'error',
    title: null,
    text: text,
    // @ts-ignore
  }).then(response => {
    if (response.isConfirmed) {
      if (callback) {
        callback()
      }
    }
  }, reject => {

  })
}


export function modalSuccess(text?, callback = null) {

  Swal.fire({
    icon: 'success',
    title: null,
    text: text,
  });
}

export function modalShowLoading() {
  Swal.showLoading();
}

export function modalHideLoading() {
  Swal.hideLoading();
}

export function modalInfo(text?, callback = null) {
  Swal.fire({
    icon: 'info',
    title: null,
    text: text,
  });
}


export async function modalInput(text, onConfirm) {

  const inputValue = '';
  // @ts-ignore
  const {value: inputVal} = await Swal.fire({
    title: text,
    input: 'text',
    inputValue: inputValue,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to input a reason!';
      }
    }
  });

  if (inputVal) {
    onConfirm(inputVal);
  }

}

export function modalConfirm(text, onConfirm, onCancel?, options: {icon?: string, confirm_label?: string } = {}) {

  if (text == null) {
    text = 'Are you sure?';
  }

  Swal.fire({
    title: text,
    // icon: (options.icon || 'question'),
    icon: <SweetAlertIcon>(options.icon || "question"),
    // showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: options.confirm_label || 'Confirm',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return new Promise((resolve, reject) => {
        if (onConfirm) {
          onConfirm(resolve, reject);
        }
      });
    }
    // @ts-ignore
  }).then((result) => {
    if (result && result.isDismissed && onCancel) {
      onCancel();
    }
  })

  // Swal.fire({
  //   title: text,
  //   icon: 'question',
  //   showLoaderOnConfirm: true,
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   // cancelButtonColor: '#fff',
  //   confirmButtonText: 'Confirm!',
  //   cancelButtonText: 'Cancel',
  //   preConfirm: () => {
  //     return new Promise((resolve, reject) => {
  //       if (onConfirm) {
  //         onConfirm(resolve, reject);
  //       }
  //     });
  //   }
  //
  // }).then(
  //   (result) => {
  //     debugger
  //     if (result.isDismissed && onCancel) {
  //       onCancel();
  //     }
  //     // onCancel(result);
  //     // handle confirm, result is needed for modals with input
  //   }, function (error) {
  //     // dismiss can be "cancel" | "close" | "outside"
  //   }
  // );

  // swal.fire({
  //   title: text,
  //   text: '',
  //   type: 'question',
  //   showLoaderOnConfirm: true,
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Confirm!',
  //   preConfirm: () => {
  //
  //     return new Promise((resolve, reject) => {
  //       if (onConfirm) {
  //         onConfirm(resolve, reject);
  //       }
  //     });
  //
  //   }
  //
  // }).then(
  //   function (result) {
  //     if (result.dismiss && onCancel) {
  //       onCancel();
  //     }
  //     // onCancel(result);
  //     // handle confirm, result is needed for modals with input
  //   }, function (error) {
  //     // dismiss can be "cancel" | "close" | "outside"
  //   }
  // );
}

// export class AppModalRef {
//     ref: NzModalRef;
//     loading = false;
//
//     close() {
//         this.ref.close();
//     }
// }

@Injectable()
export class AppModalService {

  // bsRef: BsModalRef;

  constructor(
    // private modal: NzModalService,
    // private bsModalService: BsModalService
  ) {
  }

  // openBsModal(tpl) {
  //     this.bsRef = this.bsModalService.show(tpl, {
  //         class: 'modal-fade modal-dialog-centered',
  //     });
  // }
  //
  // closeBsModal() {
  //     if (this.bsRef) {
  //         this.bsRef.hide();
  //     }
  // }

  inputText(text, onConfirm) {
    modalInput(text, onConfirm);
  }

  success(message) {
    modalSuccess(message);
  }

  info(message, cb = null) {
    modalInfo(message, cb);
  }

  error(message) {
    modalError(message);
  }

  close() {
    modalClose();
  }

  // confirm(text?, callback = null): AppModalRef {
  //
  //     const modalRef = new AppModalRef();
  //     modalRef.loading = false;
  //
  //     const resolve = () => {
  //         modalRef.close();
  //     };
  //
  //     modalRef.ref = this.modal.confirm({
  //         nzTitle: text,
  //         nzContent: '',
  //         nzOnOk: () => {
  //             callback(resolve);
  //         }
  //
  //     });
  //     return modalRef;
  //
  // }

  loading() {
    this.close();
  }

  hideLoading() {
    modalHideLoading();
  }

}
