<!--suppress XmlDuplicatedId -->

<div *ngIf="formField && formField.formControl" [ngClass]="formGroupClass">
  <label *ngIf="formField.label!=null && showLabel" class="col-form-label {{labelClass}}" for="{{formField.name}}">
    <b style="font-size:12px"> {{ labelAfter == false ? formField.label + '' : ''}}</b>
  </label>
  <div class="{{inputContainerClass}}" [ngClass]="'w-100'">
    <ng-container *ngIf="!autocomplete">
      <select *ngIf="tag == 'select' "
              id="{{formField.name}}"
              class="{{this.inputClass}}"
              [formControl]="formField.formControl"
              [ngClass]="{'is-invalid': !formField.isValid()}"
      >
        <option value="{{option.value}}" *ngFor="let option of options.data">{{option.label}}</option>
      </select>
    </ng-container>

    <p-dropdown *ngIf="formField.type=='text' && tag == 'select' && autocomplete" [options]="options.data"
                [filter]="filter" [formControl]="formField.formControl"
                [styleClass]=" !formField.isValid() ? 'is-invalid' : '' "></p-dropdown>


    <input *ngIf="(formField.type == 'text' || formField.type == 'email' || formField.type == 'password' || formField.type == 'confirm-password') && tag == 'input'"
      id="{{formField.name}}"
      [type]="getInputType()"
      class="{{this.inputClass}}"
      placeholder="{{formField.placeHolder}}"
      [formControl]="formField.formControl"
      [ngClass]="{'is-invalid': !formField.isValid()}"
      (change)="valueChange($event)"
      (keypress)="keypress($event)"
    />

    <p-calendar styleClass="w-100" dateFormat="dd/mm/yy"
                inputStyleClass="form-control  {{!formField.isValid()?'is-invalid':''}}"
                *ngIf="formField.type=='date' && tag == 'input'" [formControl]="formField.formControl"
                [disabledDates]="disabledDates" [minDate]="minDate" [maxDate]="maxDate"
                (onSelect)="valueChange($event)"></p-calendar>

    <textarea *ngIf="formField.type == 'text' &&  tag=='textarea'"
              [ngClass]="{'is-invalid': !formField.isValid()}"
              [formControl]="formField.formControl"
              class="{{this.inputClass}}"
              rows="{{textarea_rows}}"
    ></textarea>

    <p-checkbox *ngIf="formField.type=='boolean' " value="1" [binary]="true"
                [formControl]="formField.formControl"></p-checkbox>


        <p-calendar *ngIf="formField.type=='datetime' && tag == 'input'" [formControl]="formField.formControl"
                inputStyleClass="form-control  {{!formField.isValid()?'is-invalid':''}}"
                [showTime]="true"
                dateFormat="yy-mm-dd"></p-calendar>
    </div>
    <div class="clearfix"></div>
</div>
<div [ngClass]="'w-100'" *ngIf="!formField.isValid()">
    <span class="text-danger f14">{{formField.getError()}}</span>
</div>
