import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormField} from '../../form-helper';
// import {BsDatepickerConfig} from 'ngx-bootstrap';
import {environment} from '../../../../environments/environment';

import {DataOptions} from '../../../model/data-options';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnInit {

  @Input() formField: FormField;
  @Input() options: DataOptions = null;

  @Input() tag = 'input'; // input, textarea, select, nz-select
  @Input() autocomplete = false; // for tag input and select use: typeahead, nz
  @Input() filter = false; // for tag input and select use: typeahead, nz

  @Input() labelClass = 'mt-3 text-gold';
  @Input() inputContainerClass;
  @Input() inputClass = 'form-control';
  @Input() formGroupClass = 'form-group';
  @Input() hint = '';
  @Input() textarea_rows = 3;
  @Input() labelAfter = false;
  @Input() disabledDates;
  @Input() showLabel = true;

  @Input() minDate;
  @Input() maxDate;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyPress: EventEmitter<any> = new EventEmitter<any>();

  // bsConfig: Partial<BsDatepickerConfig>;

  constructor() {
    // this.bsConfig = Object.assign({}, environment.bsDatePickerConfig);
    if (this.options == null) {
      this.options = new DataOptions();
    }
  }

  valueChange($event) {
    if (this.change) {
      this.change.next($event);
    }
  }

  keypress($event) {
    if (this.keyPress) {
      this.keyPress.next($event);
    }
  }

  ngOnInit() {
  }

  getInputType() {
    if (this.formField.type == 'password' || this.formField.type == 'confirm-password') {
      return 'password';
    } else {
      return 'text';
    }
  }

}
