import {Component, HostListener, OnInit} from '@angular/core';
import {SettingDataService} from "../../model/setting-data.service";
import {MessageService} from "primeng";
import {StatsService} from "../../model/stats.service";
import {Stats} from "../../model/stats";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from "../../../environments/environment";
import {UserService} from "../../model/user.service";
import {User} from "../../model/user";
import { Location } from '@angular/common';

declare var ga: Function;

@Component({
  selector: 'app-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss']
})
export class SidenavLayoutComponent implements OnInit {

  env = environment;

  update_buyer_loader = false;
  termsAndConditions:boolean = true; //false if t&c is back

  updateStatusTimer = [];
  public stats: Stats;

  sidebarExpand = true;
  overlayEnabled = false;
  isMobile = false;
  matrixInfo: { maker: number, checker: number, approver: number };
  user: User;
  default_buyer_id: string;

  advisory: string;
  
  mainRoute: string;
  childRoute: string;

  viewScreen = false;
  viewId = '';

  constructor(
    private settingDataService: SettingDataService,
    private messageService: MessageService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    protected userService: UserService,
    protected settingService: SettingDataService,
    private activateRoute: ActivatedRoute,
    private location: Location
  ) {

    this.settingDataService.refreshGlobalSettings();
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile) {
      this.overlayEnabled = true;
    }

    if (this.overlayEnabled) {
      this.router.events.subscribe((val) => {
        this.sidebarExpand = false;
      });
    }

    this.userService.getTermsAndConditionsValue().subscribe(val => {
        // this.termsAndConditions = val; //uncomment when t&c is back
        this.termsAndConditions = true;
    });

    if (environment.ga_id) {
      ga('create', environment.ga_id, 'auto');
    }

    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            if (environment.ga_id) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            }
        }
        this.mainRoute = this.activateRoute.firstChild.routeConfig?.path || "";
        this.childRoute = this.router.url || "";
        if(this.mainRoute === 'invoice'){
            if(this.childRoute.includes('list')){
                this.viewScreen = false;
            }else{
                this.viewScreen = true;
                this.viewId = this.childRoute.split('/')?.[3];
            }
        }
    });

    this.settingService.getAllSettingsPublic().subscribe(settings => {
      if (typeof settings['important_advisory'] != 'undefined') {
        this.advisory = settings['important_advisory'];
      }
    })

    this.userService.getMe({expand: 'matrix,buyers,default_buyer'}).subscribe(
      user => {
        this.user = user;
        this.default_buyer_id = user.default_buyer ? user.default_buyer.id : null;

        if (user.matrix) {
          this.matrixInfo = user.matrix;
        }

        if (user.reset_password_token) {

          this.router.navigate(['password-reset'], {
            queryParams: {
              token: user.reset_password_token,
              'req': 1
            }
          });
        }
      }
    )


    this.userService.observeUserData.subscribe(val => {
      if (typeof val['default_buyer_id'] != 'undefined') {
        this.default_buyer_id = val['default_buyer_id'];
      }
    });
  }

  ngOnInit(): void {
  }

  onBack(){
    this.location.back();
  }

  updateDefaultBuyer() {
    this.update_buyer_loader = true;
    this.userService.updateUser({
      default_buyer_id: this.default_buyer_id,
    }).subscribe(user => {
      this.update_buyer_loader = false;
      this.userService.setLocalData({
        default_buyer_id: this.default_buyer_id,
      });
      window.location.reload();
    });

  }


}
