import {BaseObjectList} from "../../../model/base-object-list";
import {InvDocument} from "./inv-document";

export class InvDocumentList extends BaseObjectList {

  rows: InvDocument[];

  makeInstance(value) {
    return new InvDocument(value);
  }
}
