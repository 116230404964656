import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Invoice} from "./invoice";
import {GlobalService} from "../../../model/global.service";
import {ResponseBody} from "../../../model/response-body";
import {InvDocumentList} from "./inv-document-list";
import {InvDocument} from "./inv-document";
import {BaseModel} from "../../../model/base-model";


export class Project extends BaseModel {
  reference_no: string;
  progress: string;
  funding_start_date: string;
}

export class Loan extends BaseModel {
  id: string;
  status_label: string;
  reference_no: string;
}


@Injectable({
  providedIn: 'root'
})
export class InvDocumentService {

  constructor(
    private http: HttpClient
  ) {
  }


  getList(query = {}): Observable<InvDocumentList> {

    if (typeof query['expand'] == 'undefined') {
      query['expand'] = 'data';
    }

    const headers = GlobalService.getHeaders();
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document', query), {
        headers: headers
      }
    ).pipe(
      map(response => {
        return new InvDocumentList(response.data)
      }),
      catchError(GlobalService.handleError)
    )

  }

  getById(id, query= {}): Observable<InvDocument> {

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id, query),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new InvDocument(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  create(data): Observable<Invoice> {

    const headers = GlobalService.getHeaders();
    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/invoice/document'),
      data, {headers: headers}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }


  update(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/' + id), data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  getOverdueSummary(): Observable<any[]> {

    const headers = GlobalService.getHeaders();
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/overdue-summary'), {
        headers: headers
      }
    ).pipe(
      map(response => {
        return response.data;
      }),
      catchError(GlobalService.handleError)
    )

  }


  submitRequestEarlyRepayment(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/request-early-repayment'), data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  reset(id): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/reset'), {},
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }


  calculateDiscount(data): Observable<any> {

    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/loan/calculate-discount'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  requestPaymentBatch(documents): Observable<Invoice> {

    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/invoice/document/request-payment', {expand: 'loan'}),
      {
        documents
      },
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  publishInvoice(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/publish', {expand: 'loan'}),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  downloadDisclosure(id): Observable<any> {

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/disclosure-statement/' + id),
      {headers: new HttpHeaders({
          'Content-Type': 'application/pdf',
          'Authorization': 'Bearer ' + GlobalService.getToken()
        })}
    ).pipe(
      map(response => {
        return response.data;
      }),
      catchError(GlobalService.handleError)
    )

  }


  cancel(id): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/cancel'),
      {},
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  reject(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/reject'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  approve(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/approve'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }


}
