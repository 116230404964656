import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';

import {BehaviorSubject, Observable} from 'rxjs';
import {User} from './user';
import {BaseObjectList} from "./base-object-list";

@Injectable()
export class UserDataService {

  private userDataSource = new BehaviorSubject({});

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private http: HttpClient,
  ) {

  }

  getUsers(): Observable<BaseObjectList> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(
        GlobalService.getUrl('/user', {expand: ''}),
        {headers: headers}
      ).pipe(
        map(response => {
          return new BaseObjectList(response.data);
        }),
        catchError(GlobalService.handleError));
  }


}
