import {BaseModel} from '../model/base-model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {AppInjector} from '../model/app-injector';
import * as moment from 'moment';
import {CustomValidators} from 'ng2-validation';

export class FormHelper extends BaseModel {

  static buildFormGroup(builder, formFields: any): FormGroup {

    const controlsConfig = {};
    _.forIn(formFields, (formField: any, fieldName) => {

      if (formField instanceof FormField) {

        const _formField = formField as FormField;
        if (formFields.hasOwnProperty(fieldName) && !formFields.ignoreField(fieldName)) {
          _formField.name = fieldName;
          controlsConfig[fieldName] = formField.controlConfig;
        }

      } else if (formField instanceof FormFields) {
        const _formFields = formField as FormFields;
        _formFields.build();
      }

    });


    const formGroup = builder.group(controlsConfig);
    _.forIn(formGroup.controls, (control, fieldName) => {

      const _formField = formFields[fieldName] as FormField;
      _formField.formControl = control;
      _formField.onAfterBuild();
      _formField.formControl.valueChanges.subscribe(value => {
        formFields[fieldName].resetError();
      });
    });

    return formGroup;

  }

  static isValid(formField: FormField, forceErrorDisplay) {

    if (formField.formControl.disabled) {
      return true;
    }

    let is_valid = true;
    if (forceErrorDisplay) {
      is_valid = formField.formControl.valid;
    } else if (formField.formControl.touched && !formField.formControl.valid) {
      is_valid = false;
    }

    if (!formField.valid) {
      is_valid = false;
    }
    return is_valid;

  }
}

export class AbstractFormFields {
  getSubmitData() {
    return {};
  }

  setErrors(errorFields: any) {
  }

  setForceErrorDisplay(val) {
  }

  resetErrors() {
  }

}

// An Array of FormFields
// use in list of Items
export class FormFieldsArray implements AbstractFormFields {

  formFieldsArray: FormFields[];
  formArray: FormArray;
  fb: FormBuilder;

  constructor() {
    this.fb = AppInjector.get(FormBuilder);

    this.formFieldsArray = [];
    this.formArray = this.fb.array([]);
  }

  push(fieldFields) {

    if (!fieldFields._form) {
      fieldFields.build();
    }

    this.formFieldsArray.push(fieldFields);
    this.formArray.push(fieldFields._form);
  }

  setForceErrorDisplay(val) {
    this.formFieldsArray.forEach((formFields, index) => {
      formFields.setForceErrorDisplay(val);
    });
  }

  getSubmitData() {
    const data = [];
    this.formFieldsArray.forEach((formFields, index) => {
      data[index] = formFields.getSubmitData();
    });
    return data;
  }

  setErrors(errorFields: {}) {

    this.formFieldsArray.forEach((formFields: FormFields, index) => {
      if (typeof errorFields['index-' + index] !== 'undefined') {
        formFields.setErrors(errorFields['index-' + index]);
      }
    });

  }

  resetErrors() {
    this.formFieldsArray.forEach((formFields: FormFields) => {
      formFields.resetErrors();
    });
  }

  removeAt(index) {
    this.formFieldsArray.splice(index, 1);
    this.formArray.removeAt(index);
  }

  removeFormFields(formFields: FormFields) {
    const index = this.formFieldsArray.indexOf(formFields);
    this.removeAt(index);
  }

  get(index): FormFields {
    return this.formFieldsArray[index];
  }

  removeAll() {
    this.formFieldsArray = [];
    while (this.formArray.length !== 0) {
      this.formArray.removeAt(0);
    }
  }

  length() {
    return this.formFieldsArray.length;
  }

  reset() {
    this.formFieldsArray.forEach((formFields: FormFields) => {
      formFields.reset();
    });
  }

  disable() {
    this.formFieldsArray.forEach((formFields: FormFields) => {
      formFields.disable();
    });
  }

  enable() {
    this.formFieldsArray.forEach((formFields: FormFields) => {
      formFields.enable();
    });
  }

  isValid() {
    let isValid = true;
    this.formFieldsArray.forEach((formFields: FormFields) => {
      if (!formFields.isValid()) {
        isValid = formFields.isValid();
      }
    });
    return isValid;
  }

}

class FormFieldsFilter {
  group: string;
}

// Group of Fields having properties with FormField instance
export class FormFields implements AbstractFormFields {

  _form: FormGroup;
  _fb: FormBuilder;

  constructor() {
    this._fb = AppInjector.get(FormBuilder);
  }

  build(values = {}) {
    if (this._form == null) {
      this._form = FormHelper.buildFormGroup(this._fb, this);
      this.setValues(values);
    }
  }

  setErrors(errorFields: any) {

    console.log(errorFields)
    for (const fieldName in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(fieldName)) {
        continue;
      }
      console.log(fieldName)
      console.log(errorFields)
      if (this[fieldName] instanceof FormField) {
        this[fieldName].valid = false;
        this[fieldName].message = errorFields[fieldName];
      } else if (this[fieldName] instanceof FormFields) {
        const formFields = this[fieldName] as FormFields;
        formFields.setErrors(errorFields[fieldName]);
      } else if (this[fieldName] instanceof FormFieldsArray) {
        const formFieldsArray = this[fieldName] as FormFieldsArray;
        formFieldsArray.setErrors(errorFields[fieldName]);
      }
    }

  }

  getSubmitData() {

    const data = {};
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {

        if (typeof this[fieldName] !== 'undefined') {

          if (this[fieldName] instanceof FormField) {

            const _formField = this[fieldName] as FormField;
            const val = _formField.getValue();

            if (_formField.type === 'date' || _formField.type === 'datetime') {

              const _date = moment(val);
              if (_date.isValid()) {

                if (_formField.type === 'date') {
                  data[fieldName] = _date.format('YYYY-MM-DD');
                } else if (_formField.type === 'datetime') {
                  data[fieldName] = _date.format('YYYY-MM-DD HH:mm:00');
                }

              } else {
                data[fieldName] = '';
              }


            } else if (_formField.type == 'boolean') {
              data[fieldName] = val ? 1 : 0;
            } else {
              data[fieldName] = val !== undefined ? val : '';
            }

          } else if (this[fieldName] instanceof FormFields) {
            const formFields = this[fieldName] as FormFields;
            data[fieldName] = formFields.getSubmitData();
          } else if (this[fieldName] instanceof FormFieldsArray) {

            const formFieldsArray = this[fieldName] as FormFieldsArray;
            data[fieldName] = formFieldsArray.getSubmitData();

          }

        }

      }
    });
    return data;

  }

  ignoreField(f) {
    return f[0] === '_';
  }

  setForceErrorDisplay(val) {
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {

        if (this[fieldName] instanceof FormField) {
          formField.forceErrorDisplay = val;
        } else if (this[fieldName] instanceof FormFields) {
          const formFields = this[fieldName] as FormFields;
          formFields.setForceErrorDisplay(val);
        } else if (this[fieldName] instanceof FormFieldsArray) {
          const formFieldsArray = this[fieldName] as FormFieldsArray;
          formFieldsArray.setForceErrorDisplay(val);
        }

      }
    });
  }

  resetErrors() {
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {
        if (this[fieldName] instanceof FormField) {
          formField.valid = true;
          formField.message = '';
        } else if (this[fieldName] instanceof FormFields) {
          const formFields = this[fieldName] as FormFields;
          formFields.resetErrors();
        } else if (this[fieldName] instanceof FormFieldsArray) {
          const formFieldsArray = this[fieldName] as FormFieldsArray;
          formFieldsArray.resetErrors();
        }
      }
    });
  }

  setValues(values) {
    _.forIn(values, (value, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {

        if (this[fieldName] instanceof FormField) {
          const formField = this[fieldName] as FormField;
          if (formField.type == 'date') {
            formField.setValue(moment(value).format('YYYY-MM-DD'));
          } else if (formField.type == 'datetime') {
            formField.setValue(moment(value).format('YYYY-MM-DDTHH:mm:ss'));
          } else {
            formField.setValue(value);
          }
        }
      }
    });
  }

  reset() {
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {
        if (this[fieldName] instanceof FormField) {
          formField.reset();
        } else if (this[fieldName] instanceof FormFields) {
          const formFields = this[fieldName] as FormFields;
          formFields.reset();
        } else if (this[fieldName] instanceof FormFieldsArray) {
          const formFieldsArray = this[fieldName] as FormFieldsArray;
          formFieldsArray.reset();
        }
      }
    });
  }

  isValid() {

    let is_valid = true;
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {
        // console.log(fieldName, formField.isValid());
        if (is_valid) { // && this[fieldName] instanceof FormFields
          is_valid = formField.isValid();
        }
      }
    });
    // console.log(is_valid)
    // console.log(this._form.valid)
    // console.log(this._form.valid, this._form)
    // return this._form.valid && is_valid;
    return is_valid;

  }

  _status(value) {
    _.forIn(this, (formField, fieldName) => {
      if (!this.ignoreField(fieldName) && this.hasOwnProperty(fieldName)) {
        if (this[fieldName] instanceof FormField) {
          if (formField.formControl) {
            if (value) {
              formField.formControl.enable();
            } else {
              formField.formControl.disable();
            }
          }
        } else if (this[fieldName] instanceof FormFields) {
          const formFields = this[fieldName] as FormFields;
          if (value) {
            formFields.enable();
          } else {
            formFields.disable();
          }
        } else if (this[fieldName] instanceof FormFieldsArray) {
          const formFieldsArray = this[fieldName] as FormFieldsArray;

          if (value) {
            formFieldsArray.enable();
          } else {
            formFieldsArray.disable();
          }
        }
      }
    });
  }

  disable() {
    this._status(false);
  }

  enable() {
    this._status(true);
  }

}

// Individual field that hold the single formControl
// this class helps with error handling client side and server side
export class FormField {

  name = ''; // internal only
  group = '';
  controlConfig = null;
  controlValidations = null;
  formControl: FormControl = null;

  valid = true;
  message = '';

  type = 'text'; // Default: text;  Data Type:  text, date, datetime, email, password, boolean, confirm-password
  label = null;
  placeHolder = null;

  value = '';
  disabled = false;
  required = false;

  forceErrorDisplay = false;

  constructor(fieldConfig = null) {
    Object.assign(this, fieldConfig);

    const _controlValidations = this.getValidations();
    if (this.controlConfig == null) {
      const _v = this.value || '';
      if (_controlValidations.length > 0) {
        this.controlConfig = [_v, Validators.compose(_controlValidations)];
      } else {
        this.controlConfig = [_v];
      }
    }
  }

  updateValidations() {

    const _controlValidations = this.getValidations();
    this.formControl.setValidators(Validators.compose(_controlValidations));

  }

  getValidations() {
    const _controlValidations = [];
    if (this.required) {
      _controlValidations.push(Validators.required);
    }
    if (this.type && this.type == 'email') {
      _controlValidations.push(CustomValidators.email);
    }

    if (this.controlValidations) {
      this.controlValidations.forEach(_v => {
        _controlValidations.push(_v);
      });
    }
    return _controlValidations;
  }

  onAfterBuild() {
    this.setValue(this.value);
    if (this.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  resetError() {
    this.valid = true;
    this.message = '';
  }

  getError() {

    let error = this.message;
    if (!this.valid && this.message !== '') {
      error = this.message;
    } else if (this.formControl.errors && this.formControl.errors.required) {

      // for client side checking, no need to show cause the red border will show on the input field
      error = 'This field is required.';

    } else if (this.formControl.errors && this.formControl.errors.email) {
      error = 'Email is invalid.';
    } else if (this.type == 'confirm-password' && this.formControl.errors && this.formControl.errors.equalTo) {
      error = 'Confirm password does not matched with your password.';
    }
    return error;

  }

  isValid() {
    return FormHelper.isValid(this, this.forceErrorDisplay);
  }

  getValue() {
    return this.formControl.value;
  }

  setValue(val) {
    this.formControl.setValue(val);
  }

  reset() {
    this.forceErrorDisplay = false;
    this.resetError();
    this.setValue('');
  }

  build() {
    this.formControl = new FormControl(this.controlConfig);
    this.onAfterBuild();
  }

  disable() {
    this.formControl.disable();
  }

  enable() {
    this.formControl.enable();
  }

  setRequire(val) {
    this.required = val;
    this.updateValidations();
  }

  formatted() {
    if (this.type == 'date') {
      const val = this.getValue();
      if (val) {
        return moment(val).format('DD/MM/YYYY');
      }
    } else {
      return this.getValue();
    }
  }

  apiFormat() {
    if (this.type == 'date') {
      const val = this.getValue();
      let _m;
      if (typeof val == 'string') {
        _m = moment(val, 'DD/MM/YYYY');
      } else {
        _m = moment(val);
      }
      if (val && _m && _m.isValid()) {
        return _m.format('YYYY-MM-DD');
      } else {
        return val;
      }

    } else {
      return this.getValue();
    }
  }


}

